@import "variables.scss";
.hero{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 90vh;
    margin-top: 3em;
    .leftSide{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
        padding: 1em;
        min-width: 300px;
        z-index: 2;
        .header{
            font-size: 5em;
            text-shadow: $text-shadow;
            color: white;
            margin: 0;
            line-height: 1.2em;
            // text-align: center;
        }
        .subHeader{
            font-size: 1.5em;
            text-shadow: $text-shadow;
            color: $color-3;
            margin: 0;
        }
        .skillsContainer{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 1em;
            width: 50%;
            // height: 100px;
            margin-top: 2em;
            .skill{
                padding: 0.5em;
                border-radius: 5px;
                box-shadow: $outer-shadow, inset 0px 0px 2px rgba(255, 255, 255, 0.5);
                p{
                    margin: 0;
                    color: white;
                    font-size: 1em;
                    text-indent: 0;
                    line-height: normal;
                    font-weight: 600;
                }
                &.html{
                    background-color: $color-2;
                }
                &.css{
                    background-color: $color-1;
                }
                &.javascript{
                    background-color: rgba($color-3, 0.5);
                }
                &.react{
                    background-color: $color-2;
                }
                &.swiftui{
                    background-color: rgba($color-3, 0.5);
                }
                &.python{
                    background-color: $color-1;
                }
                &.flask{
                    background-color: rgba($color-3, 0.5);
                }
            }
        }
    }
    .rightSide{
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 50%;
        height: 85vh;
        transform: translateY(5rem);
        .ty{
            width: 100%;
            max-width: 100%;
            min-width: 300px;
            -webkit-filter: drop-shadow(0px 0px 5px #666666);
            filter: drop-shadow(0px 0px 5px #666666);
        }
    }
}

// Phone Styles
@media screen and (max-width: $mobileSize) {
    .hero{
        position: relative;
        .leftSide{
            position: absolute;
            left: 0;
            width: 100%;
            .header{
                font-size: 3em;
                text-align: center;
            }
            .subHeader{
                font-size: 1.3em;
                text-align: center;
            }
            .skillsContainer{
                width: 100%;
            }
        }
        .rightSide{
            width: 100%;
            .ty{
                width: 100%;
                min-width: auto;
            }
        }
    }
}
