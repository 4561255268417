@import "variables";
.navContainer{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    .navLinks{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1em;
        width: 100%;
        padding: 1em;
        margin-top: 3em;
        background-color: $color-2;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: $outer-shadow, $inner-shadow;
        transform: translateX(-200%);
        z-index: 100;
        transition: all 300ms ease-out;
        &.showingNav{
            transform: translateX(-10px);
        }
        .navLink{
            font-family: "Sigmar", cursive;
            font-size: 1.2em;
            background-color: transparent;
            border: none;
            height: auto;
            color: white;
            text-shadow: $text-shadow;
            text-decoration: none;
            transition: all 150ms ease-in-out;
            &.hero{
                margin-top: 0;
            }
            &:hover{
                color: $color-3;
                cursor: pointer;
            }
        }
    }
}