@import "style/variables.scss";

#root{
  width: 100%;
}
p{
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.5em;
  text-indent: 2em;
}
.App{
  position: relative;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(315deg, $color-1, $color-2);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  margin: auto;
  min-height: 100vh;
  .mainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 10%;
    width: 90%;
    margin-top: 2em;
    transition: all 200ms ease-out;
    // &.projects{
    //   width: 50%;
    //   min-width: 500px;
    // }
    .infoContainer{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 85vh;
      margin-top: 2em;
      border-radius: 15px;
      background-color: $color-2;
      box-shadow: $outer-shadow, $inner-shadow;
      overflow-x: hidden;
      overflow-y: scroll;
      scroll-behavior: smooth;
      transition: all 200ms ease-out;
    }
  }
}

@media screen and (max-width: $mobileSize) {
  .App{
    .mainContainer{
      margin-top: 3em;
      width: 100%;
    }
  }
}
