@import "variables.scss";
.section2{
    position: relative;
    display: flex;
    justify-content: space-around;
    gap: 2em;
    max-width: 100%;
    min-height: 90vh;
    padding-top: 20em;
    background-image: url("../svgs/background_wave.svg");
    background-size: cover;
    -webkit-filter: drop-shadow(0px 0px 10px #00000077);
    filter: drop-shadow(0px 0px 10px #00000077);
    .leftSide{
        width: 50%;
        background-color: $color-3;
        img{
            width: 100%;
        }
    }
    .rightSide{
        width: 50%;
        padding: 2em;
        color: white;
    }
}

@media screen and (max-width: $mobileSize) {
    .section2{
        flex-direction: column;
        justify-content: left;
        gap: 0;
        width: 100%;
        min-height: auto;
        .leftSide{
            display: none;
            width: 100%;
            img{
                width: 100%;
                min-height: 100%;
            }
        }
        .rightSide{
            width: calc(100% - 4em);
        }
    }
  }