@import "variables.scss";

.section3{
    position: relative;
    display: flex;
    // justify-content: space-around;
    gap: 2em;
    max-width: 100%;
    min-height: 90vh;
    background: linear-gradient($color-1, $color-2);
    .leftSide{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        .uvm{
            width: 100%;
        }
        .uvmHeader{
            position: absolute;
            bottom: 0;
            left: 5%;
            font-size: 10em;
            color: #3b7740;
            margin: 0;
            padding: 0;
            text-shadow: none;
        }
    }
    .rightSide{
        width: 50%;
        padding: 2em;
        color: white;
        padding-top: 5em;
        z-index: 2;
        .paragraph{
            padding: 2rem;
            background-color: rgba($color-2, 0.7);
            border-radius: 10px;
        }
    }
}

@media screen and (max-width: $mobileSize) {
    .section3{
        flex-direction: column;
        justify-content: left;
        gap: 0;
        width: 100%;
        min-height: auto;
        .leftSide{
            display: none;
            width: 100%;
            img{
                width: 100%;
                min-height: 100%;
            }
        }
        .rightSide{
            width: calc(100% - 4em);
        }
    }
  }