@import "variables.scss";

.projectsTitle{
    width: 100%;
    margin-left: 1em;
}
.projectsContainer{
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    grid-auto-rows: 150px;
    gap: 1em;
    padding: 0 1em;
    padding: 1em;
    width: calc(100% - 2em);
    color: #6f6f6f;
    color: #be8adc;
}

@media screen and (max-width: $mobileSize) {
    .projectsContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}