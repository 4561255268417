@import "variables.scss";
.contactContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
    width: 50%;
    margin: 5rem 0;
    .heading{
        margin: 0;
    }
    .subheading {
        color: white;
        margin: 1rem 0;
    }
    .buttonContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 40%;
        margin-top: 2rem;
        .contactButton {
            padding: 0.5rem 2rem;
            border-radius: 100px;
            border: solid 2px $color-1;
            color: $color-1;
            background-color: $color-3;
            box-shadow: $outer-shadow;
            font-weight: 700;
            text-decoration: none;
            transition: all 200ms ease-in-out;
            &:hover{
                cursor: pointer;
                transform: scale(1.1);
            }
        }
    }
}
@media screen and (max-width: $mobileSize) {
    .contactContainer{
        width: 90%;
        .heading {
            text-align: center;
        }
        .buttonContainer{
            width: 100%;
        }
    }
}