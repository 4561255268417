@import "variables.scss";
.hamburger{
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    transition: all 150ms ease-in-out;
    .hamburgerLine{
        width: 100%;
        height: 0.1rem;
        background-color: $color-4;
        border-radius: 10px;
        transition: all 150ms ease-in-out;
    }
    &:hover{
        cursor: pointer;
        transform: scale(1.1);
    }
}
.hamburger.close{
    .hamburgerLine{
        &:nth-child(1){
            transform: rotate(45deg) translate(0.35rem, 0.35rem);
        }
        &:nth-child(2){
            opacity: 0;
        }
        &:nth-child(3){
            transform: rotate(-45deg) translate(0.35rem, -0.35rem);
        }
    }
}